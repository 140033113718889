import React from "react";
import "./GlassyLetter.scss";

function GlassyLetter(props) {
  return (
    <div className="glass-background">
      <div className="number-typo"> {props.number}</div>
      <div className="letter-typo">{props.letter}</div>
    </div>
  );
}

export default GlassyLetter;
