import React from "react";
import "./Home.scss";
import Navbar from "../components/Navbar/Navbar";
import GlassyLetter from "../components/GlassyLetter/GlassyLetter";
import SolutionSection from "../components/SolutionSection/SolutionSection";
import TechnologiesSection from "../components/TechnologiesSection/TechnologiesSection";
import Work from "./Work";
import About from "../components/About/About";
import ContactUs from "../components/ContactUs/ContactUs";

function Home() {
  return (
    <main className="home main-container">
      <Navbar />
      {/* Hero section */}
      <div id="home" className="quote-container">
        <div className="quote-typo">
          <GlassyLetter letter="Cr" number={24} />
          afting Your Dream
        </div>
        <div className="quote-typo">
          <GlassyLetter letter="S" number={16} /> olution
        </div>
      </div>

      {/* Work section */}
      <Work />

      {/* Solutions section */}
      <SolutionSection />

      {/* Technologies section */}
      <TechnologiesSection />

      {/* About section */}
      <About />

      {/* Contact section */}
      <ContactUs />
    </main>
  );
}

export default Home;
