import React from "react";
import "./Work.scss";
import ballon from "../assets/images/Sphere_Matrix.png";
import WorkTemplate from "../components/WorkTemplate/WorkTemplate";
import Img from "../assets/images/work_1.png";

function Work() {
  return (
    <div className="work-main-container">
      <img className="ballon-position" src={ballon} alt="ballon" />

      <div className="work-text-container">
        <p className="section-title white">Work</p>
        {/* <div className="header-underline"></div> */}
      </div>
      <WorkTemplate
        description="Crafting an innovative mobile telecommunication app, the solution integrates  seamless data and voice add-ons, intuitive package management,  effortless bill payments, and a dynamic chat service. Demonstrating  expertise in sleek, user-centric telecommunication solutions, the app elevates the  digital experience."
        img={Img}
      />
    </div>
  );
}

export default Work;
