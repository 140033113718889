import React, { useState } from "react";
import "./Navbar.scss";
import { Link } from "react-router-dom";
import close from "../../assets/Icons/close.png";
import open from "../../assets/Icons/open.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 500);
  const handleLogoClick = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (window.innerWidth <= 500) {
      setIsOpen(false);
    }
  };
  return (
    <header>
      <nav className={isOpen ? "" : "open"}>
        <div className="mobile-logo">
          <img
            src={isOpen ? close : open}
            alt="logo-mobile"
            onClick={handleLogoClick}
          />
        </div>
        <div className="mobile-logo-dcode">
          / &gt;&nbsp;&nbsp;&nbsp;&nbsp; dcodelabs
        </div>
        <ul>
          <li>
            <a onClick={closeMenu} href="#home" className="logo">
              / &gt;&nbsp;&nbsp;&nbsp;&nbsp; dcodelabs
            </a>
          </li>
          <li>
            <a onClick={closeMenu} href="#solutions">
              Solutions
            </a>
          </li>
          <li>
            <a onClick={closeMenu} href="#about">
              About
            </a>
          </li>
          <li>
            <a onClick={closeMenu} href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
