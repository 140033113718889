import React from 'react'
import "./contactus-style.css"
function ContactUs() {

  return (
  <div id='contact' className="main-contactus">
      <div className="section-title">Contact</div>
      <div className="contactus-form">
          <div className="dream-heading">Have a dream ?</div>
          <div className="dream-heading">Lets realize it <b>together</b></div>
          <div className="contact-form">
                <div className="input-sec-1">
                <input className="input-1" type="text" id="name" name="name" placeholder="Name"/>
                <input className="input-1" type="email" id="email" name="email" placeholder="Email"/>
                 </div>
                <textarea className="input-2"  id="message" name="message" placeholder="Message"></textarea>
                <button className="contact-us-submit" type="submit">Submit</button>
            </div>
      </div>
  </div>
  )
}

export default ContactUs