import "./App.css";
import React from "react";
import Footer from "./components/Footer/Footer";
import Home from "./routes/Home";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
      <title>Dcodelabs - Crafting Your Dream Solution</title>
      <meta name="description" content="Discover Dcodelabs' innovative software solutions, including our mobile telecommunication app with seamless data integration, intuitive management, and dynamic features." />
      <meta name="keywords" content="Dcodelabs, solutions, UI/UX design, software development, DevOps, cloud migration, web development, mobile app development, API integration" />
      
      <meta name="description" content="Create engaging and intuitive interfaces with our UX/UI design services." />
      
      <meta name="description" content="Bring your ideas to life with our custom software development services." />
     
      <meta name="description" content="Streamline your software development and deployment process with our DevOps services." />
     
      <meta name="description" content="Leverage the power of the cloud with our scalable and secure cloud computing services." />
     
      <meta name="description" content="Transform your online presence with our cutting-edge web development services." />
     
      <meta name="description" content="Reach your audience on the go with our custom mobile app development solutions." />
      
      <meta name="description" content="Enhance your software capabilities with our API development and integration services." />
      </Helmet>
      <Home />

      <Footer />
    </div>
  );
}

export default App;
