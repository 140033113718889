import React from 'react'
import './Glass.scss'
import classNames from 'classnames'

function Glass({ children, className }) {
  return (
    <div className={classNames('glass card', className)}>
        { children }
    </div>
  )
}

export default Glass