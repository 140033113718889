import React from 'react';
import './about.scss';
import quoteIconFlip from '../../assets/Quote Icon flip.png';
import quoteIcon from '../../assets/Quote Icon.png';
import Team from './Team';

const About = () => {
  return (
    <section id='about' className="about-container">
      <div className="about-content">
        <span className="heading-about">
          <p className="section-title white">About</p>
        </span>
        <Team/>
        <div className="quote-section">
          <img src={quoteIconFlip} alt="Quote Icon" className="quote-icon-flip" />
          <p className="quote-text">
               Dcodelabs is where dreams become reality. <span className="bold">[Cr]</span> represents strength and reliability, the sturdy foundation of our solutions.
              <span className="bold">[S]</span> symbolizes innovation, driving the unseen complexities of our creations. Together, <span className="bold">[Cr]</span> and <span className="bold">[S]</span> craft transformative solutions,
              tailored to bring each dream to life. At dCodelabs, we <span className="bold">[Cr]</span>eate your dream <span className="bold">[S]</span>olution, building the future one line of code at a time.
          </p>
          <img src={quoteIcon} alt='Quote Icon' className='quote-icon' />
        </div>
      </div>
    </section>
  );
};

export default About;
