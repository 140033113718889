import React, { useEffect, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import g1 from '../../assets/g1.png';
import g2 from '../../assets/g2.png';
import g3 from '../../assets/g3.png';
import g4 from '../../assets/g4.png';
import g5 from '../../assets/g5.png';
import './team.scss';

const Team = () => {
  const { scrollYProgress } = useScroll();
  const [transformValues, setTransformValues] = useState({
    x1: [0, 1100],
    x2: [0, 550],
    x3: [0, 0],
    x4: [0, -550],
    x5: [0, -1000]
  });

  useEffect(() => {
    const updateTransformValues = () => {
      if (window.matchMedia('(max-width: 425px)').matches) {
        setTransformValues({
          x1: [0, 250],
          x2: [0, 120],
          x3: [0, 0],
          x4: [0, -120],
          x5: [0, -250]
        });
      }else if (window.matchMedia('(max-width: 576px)').matches) {
        setTransformValues({
          x1: [0, 400],
          x2: [0, 200],
          x3: [0, 0],
          x4: [0, -200],
          x5: [0, -400]
        });
      } else if (window.matchMedia('(max-width: 768px)').matches) {
        setTransformValues({
          x1: [0, 500],
          x2: [0, 250],
          x3: [0, 0],
          x4: [0, -250],
          x5: [0, -500]
        });
      } else if (window.matchMedia('(max-width: 992px)').matches) {
        setTransformValues({
          x1: [0, 630],
          x2: [0, 295],
          x3: [0, 0],
          x4: [0, -295],
          x5: [0, -630]
        });
      }  else if (window.matchMedia('(max-width: 1200px)').matches) {
        setTransformValues({
          x1: [0, 700],
          x2: [0, 340],
          x3: [0, 0],
          x4: [0, -340],
          x5: [0, -700]
        });
      }else {
        
        setTransformValues({
          x1: [0, 1100],
          x2: [0, 550],
          x3: [0, 0],
          x4: [0, -550],
          x5: [0, -1100]
        });
      }
    };

   
    updateTransformValues();

    
    window.addEventListener('resize', updateTransformValues);

   
    return () => window.removeEventListener('resize', updateTransformValues);
  }, []);

  const x1 = useTransform(scrollYProgress, [0.1, 0.7], transformValues.x1);
  const x2 = useTransform(scrollYProgress, [0.1, 0.7], transformValues.x2);
  const x3 = useTransform(scrollYProgress, [0.1, 0.7], transformValues.x3);
  const x4 = useTransform(scrollYProgress, [0.1, 0.7], transformValues.x4);
  const x5 = useTransform(scrollYProgress, [0.1, 0.7], transformValues.x5);

  return (
    <section className='image-container'>
      <div className='image-row'>
        <motion.img
          src={g1}
          alt='Team Member 1'
          className='team-image img1'
          style={{ x: x1 }}
        />
        <motion.img
          src={g2}
          alt='Team Member 2'
          className='team-image img2'
          style={{ x: x2 }}
        />
        <motion.img
          src={g3}
          alt='Team Member 3'
          className='team-image img3'
          style={{ x: x3 }}
        />
        <motion.img
          src={g4}
          alt='Team Member 4'
          className='team-image img4'
          style={{ x: x4 }}
        />
        <motion.img
          src={g5}
          alt='Team Member 5'
          className='team-image img5'
          style={{ x: x5 }}
        />
      </div>
    </section>
  );
};

export default Team;
