import React, {useEffect} from 'react'
import SolutionItem from '../SolutionItem/SolutionItem'
import './SolutionSection.scss'

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Scrollbar, Pagination, A11y } from 'swiper/modules';

function lerp(start, end, t){
    return start * ( 1 - t ) + end * t;
}

function SolutionSection() {

//     useEffect(() => {
//         const projectsSticky = document.querySelector('.sticky-wrapper');
//         const projectSlider = document.querySelector('.solutions');

//         let projectTargetX = 0;
//         let projectCurrentX = 0;

//         let percentages = {
//             small: 3500,
//             medium: 175,
//             extra_extra_medium: 140,
//             extra_medium: 115,
//             large: 145
//         }

//         let limit = window.innerWidth <= 600 ? percentages.small :
//                     window.innerWidth <= 1100 ? percentages.medium :
//                     window.innerWidth <= 1400 ? percentages.extra_extra_medium :
//                     window.innerWidth <= 1800 ? percentages.extra_medium :
//                     percentages.large

//         function setLimit(){
//             limit = window.innerWidth <= 600 ? percentages.small :
//                     window.innerWidth <= 1100 ? percentages.medium :
//                     window.innerWidth <= 1400 ? percentages.extra_extra_medium :
//                     window.innerWidth <= 1800 ? percentages.extra_medium :
//                     percentages.large
//         }

//         window.addEventListener('resize', setLimit);

//         function animateProjects(){
//             let offsetTop = projectsSticky.parentElement.offsetTop;
//             const scrollTop = window.pageYOffset;
//             // console.log(scrollTop);
//             let percentage = ((scrollTop - offsetTop) / window.innerHeight) * 100;
//             percentage = percentage < 0 ? 0 : percentage > limit ? limit : percentage;
//             projectTargetX = percentage;
//             projectCurrentX = lerp(projectCurrentX, projectTargetX, .1);
//             projectSlider.style.transform = `translate3d(${-(projectCurrentX)}vw, 0 , 0)`;
//         }

//         function animate(){
//             animateProjects();
//             requestAnimationFrame(animate);
//         }

//         animate();

//         window.addEventListener('scroll', ()=>{
//             animate();
//         })

//   }, []);

  return (
    <section id='solutions' className='section-solutions'>
        {/* <div className="sticky-wrapper"> */}
            <p className="section-title">Solutions</p>
            <img className='dynamic-item item1' src={process.env.PUBLIC_URL + `/images/box.svg`} alt="" />
            <img className='dynamic-item item2' src={process.env.PUBLIC_URL + `/images/pin.svg`} alt="" />
            <img className='dynamic-item item3' src={process.env.PUBLIC_URL + `/images/diamond.svg`} alt="" />
            <img className='dynamic-item item4' src={process.env.PUBLIC_URL + `/images/half-globe.svg`} alt="" />
            <img className='dynamic-item item5' src={process.env.PUBLIC_URL + `/images/triangle.svg`} alt="" />
            {/* <div className="solutions">
                {solutions.map( solution => (
                    <SolutionItem
                    imageUrl={solution.imageUrl}
                    title={solution.title}
                    content={solution.content}
                    />
                ))}
            </div>
            <div className="empty-box">
                
            </div>
            <div className="empty-box">

            </div> */}

{/* <div className="solutions"> */}
<Swiper 
        spaceBetween={0}
        breakpoints={{
          0: {
            slidesPerView: 1,
            // scrollbar: {
            //   el: '.swiper-scrollbar',
            //   enabled: true,
            //   hide: false,
            // },
            pagination: {
                clickable: true,
                dynamicBullets: true
            }
          },
          1580: { 
            slidesPerView: 3,
          },
          970: { 
            slidesPerView: 2,
          }
        }}
        modules={[Navigation, Scrollbar, Pagination, A11y]} 
        pagination={{
            dynamicBullets: true,
        }}
        navigation={{ nextEl: ".next-arrow", prevEl: ".prev-arrow" }}
        className="project-slider"
        style={{
          overflowY: "visible"
        }}
        >{solutions.map( solution => (
                  <SwiperSlide
                  style={{display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}}
                  >
                    <SolutionItem
                    imageUrl={solution.imageUrl}
                    title={solution.title}
                    content={solution.content}
                    />
                    </SwiperSlide>
                ))}
        </Swiper>
        <div className="swiper-nav-btns">
          <div className="prev-arrow">
            <img src={process.env.PUBLIC_URL + '/images/arrow-prev.svg'} alt="hero" />
          </div>
          <div className="next-arrow">
            <img src={process.env.PUBLIC_URL + '/images/arrow-next.svg'} alt="hero" />
          </div>
        </div>

        {/* <div className="swiper-scrollbar"
        style={{bottom: 14, height: 6}}
        ></div> */}
        {/* </div> */}
    </section>
  )
}

export default SolutionSection

const solutions = [
    {
        imageUrl: 'solution-1.svg',
        title: 'UI/UX Design',
        content: 'Create engaging and intuitive interfaces with our UX/UI design services. We focus on user experience to ensure your digital products are not only beautiful but also functional.'
    },
    {
        imageUrl: 'solution-1.svg',
        title: 'Software Development',
        content: 'Bring your ideas to life with our custom software development services. We specialize in creating tailored software solutions that meet the unique needs of your business.'
    },
    {
        imageUrl: 'solution-1.svg',
        title: 'DevOps as a Service',
        content: 'Streamline your software development and deployment process with our DevOps services. We integrate development and operations to improve collaboration, increase efficiency, and accelerate delivery.'
    },
    {
        imageUrl: 'solution-1.svg',
        title: 'Cloud migration',
        content: 'Leverage the power of the cloud with our scalable and secure cloud computing services. We offer cloud migration, management, and optimization to help you reduce costs and increase efficiency.'
    },
    {
        imageUrl: 'solution-1.svg',
        title: 'Web Development',
        content: 'Transform your online presence with our cutting-edge web development services. We build responsive, user-friendly websites tailored to your business needs, ensuring a seamless user experience across all devices.'
    },
    {
        imageUrl: 'solution-1.svg',
        title: 'Mobile application Development',
        content: 'Reach your audience on the go with our custom mobile app development solutions. We design and develop high-performing, feature-rich mobile applications for both iOS and Android platforms.'
    },
    {
        imageUrl: 'solution-1.svg',
        title: 'API development and Integration',
        content: 'Enhance your software capabilities with our API development and integration services. We build and connect APIs to ensure seamless communication between your applications and third-party services.'
    }
]