import React from "react";
import './footer.scss';
import ig from '../../assets/ig.png';
import dc from '../../assets/dc.png';
import be from '../../assets/be.png';
import dr from '../../assets/dr.png';
import x from '../../assets/x.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <p>&copy; 2024. All rights reserved.</p>
        </div>
        <div className="footer-section">
          <p>&#47;&gt; dcodelabs</p>
        </div>
        <div className="footer-section footer-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="instagram" />
          </a>
          <a href="_" target="_blank" rel="noopener noreferrer">
            <img src={dc} alt="discord" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src={be} alt="behance" />
          </a>
          <a href="https://dribbble.com/" target="_blank" rel="noopener noreferrer">
            <img src={dr} alt="dribble" />
          </a>
          <a href="https://x.com" target="_blank" rel="noopener noreferrer">
            <img src={x} alt="x" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
