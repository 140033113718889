import React from 'react'
import Glass from '../Glass/Glass'
import './SolutionItem.scss'

function SolutionItem({imageUrl, title, content}) {
  return (
    <Glass className="solution">
        <img src={process.env.PUBLIC_URL + `/images/${imageUrl}`} alt="solution" />
        <p className="title">{title}</p>
        <p className="content">{content}</p>
    </Glass>
  )
}

export default SolutionItem