import React from "react";
import "./WorkTemplate.scss";
function WorkTemplate(props) {
  return (
    <div className="worktemplate-main-container">
      <div className="description-typo">{props.description}</div>
      <div className="work-diplay-container">
        <img className="image-sizing" src={props.img} alt="displayImage" />
      </div>
    </div>
  );
}

export default WorkTemplate;
