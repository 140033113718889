import React from 'react'
import './TechnologiesSection.scss'

function TechnologiesSection() {
  return (
    <section className='section-technologies'>
      <div className="tech-slide">
        {technologies.map( technology => (
            <img src={process.env.PUBLIC_URL + `/images/technologies/${technology}.svg`} alt="solution" />
        ))}
      </div>
      <div className="tech-slide">
        {technologies.map( technology => (
            <img src={process.env.PUBLIC_URL + `/images/technologies/${technology}.svg`} alt="solution" />
        ))}
      </div>
    </section>
  )
}

export default TechnologiesSection

const technologies = [
    'react',
    'figma',
    'postgres',
    'flutter',
    'gradle',
    'java',
    'dotnet',
    'redis',
    'mongodb',
    'csharp',
    'js',
    'php',
    'sass'
]